* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.gwel-name {
  font-family: 'Bauhaus 93', 'PT Sans', sans-serif;
  font-size: 40px;
  color: #fff;
  display: contents;
} 