  .gwel-home {
    display: flex;
    height: 88vh; 
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    background-image: url('../../images/GWEL-chn-eng.png');
    background-position: center;
    background-size: 68%;
    background-repeat: no-repeat;
  }